@import '@/styles/vars';

.base-nft {
  position: relative;

  .brand-logo {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
    z-index: 1;
  }

  .burn-fire-item {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;

    .burn-fire-image {
      mix-blend-mode: lighten;
    }
  }

  .empty-nft-wrap-text {
    width: 86%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);

    .empty-nft-text {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
    }

    @media screen and (max-width: $MAX_WIDTH_MOBILE) {
      width: 90%;

      .empty-nft-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.1;
      }
    }
  }
}

.base-nft,
.elite-nft {
  clip-path: polygon(12% 0, 100% 0, 100% 88%, 88% 100%, 0 100%, 0 12%);
  border-radius: 4px;
  overflow: hidden;

  .space-3-image {
    width: 100%;
    height: 100%;
  }

  .base-nft-image,
  .elite-nft-image {
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}
