.first-page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  transition: all 0.5s ease-in-out;
  backdrop-filter: blur(2px);
}
