@import '@/styles/vars.scss';

.burn-nft-container {
  background:
    url('/images/clip-path-border.svg') no-repeat center,
    linear-gradient(
      60deg,
      rgba(3, 141, 130, 0.35) 20%,
      rgba(15, 219, 209, 0.05) 100%
    );
  border-radius: 4px;
  backdrop-filter: blur(10px);
  clip-path: polygon(7.2% 0, 100% 0, 100% 92%, 92.7% 100%, 0 100%, 0 6%);
  padding: 0 16px;

  .burn-nft {
    &--header {
      height: max-content;
      padding: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);

      .burn-nft-title {
        color: #0ec9c0;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;

        &--warning {
          color: #ff7300;
          font-size: 17px;
          font-weight: 500;
        }
      }

      @media screen and (max-width: $MAX_WIDTH_MOBILE) {
        .burn-nft-title,
        .burn-nft-title--warning {
          font-size: 15px !important;
        }

        .countdown-container {
          width: 100%;

          .countdown-title {
            width: 44px !important;
            flex-shrink: 0;
          }

          .space-3-flex {
            width: 100%;
            .countdown-item {
              min-width: 57px !important;
              flex-grow: 1;
              text-align: center;
            }
          }
        }
      }

      .countdown-container {
        .countdown-title {
          font-size: 13px;
        }

        .countdown-number {
          font-size: 14px;
        }

        .countdown-text {
          font-size: 10px;
        }
      }
    }

    &--content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      gap: 24px;

      .live-item {
        display: flex;
        align-items: center;

        padding: 6px 12px;
        gap: 6px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.06);

        .icon-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #c7ff00;
          position: relative;
          animation: pulse 2s infinite;
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(199, 255, 0, 0.12);
          }
          70% {
            box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
          }
        }

        .space-3-typography {
          color: #c7ff00;
          font-weight: 600;
          letter-spacing: 0.2px;
          line-height: 1.1;
        }
      }

      @media screen and (max-width: $MAX_WIDTH_MOBILE) {
        gap: 20px;
        height: 332px;
      }
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 8px !important;

    .burn-nft--header {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
}
