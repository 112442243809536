@import '@/styles/vars.scss';

.burn-round {
  &-container {
    position: relative;
    border-radius: 4px;
    background:
      url('/images/clip-path-border.svg') no-repeat center,
      linear-gradient(
        325deg,
        rgba(33, 39, 39, 0.3) 34.02%,
        rgba(41, 48, 48, 0.3) 94.66%
      );
    clip-path: polygon(7.2% 0, 100% 0, 100% 92%, 92.7% 100%, 0 100%, 0 6%);
    backdrop-filter: blur(20px);
  }
}

.burn-total-progress {
  .space-3-progress-inner {
    box-shadow: 0 0 8px 0 rgba(248, 248, 248, 0.25) inset;
    border: 1.5px solid rgba(248, 248, 248, 0);
    background: rgba(40, 40, 40, 0.7);
    background-blend-mode: luminosity;
  }
}

.btn-amount {
  background: url('/images/small-clip-path-border.svg') center / cover no-repeat;
  clip-path: polygon(7.2% 0, 100% 0, 100% 92%, 92.7% 100%, 0 100%, 0 6%);

  &:hover {
    background: url('/images/small-clip-path-border.svg') center / cover
      no-repeat !important;
  }
}

.burn-round-container {
  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .burn-round-item {
      .countdown-title {
        font-size: 13px !important;
      }

      .countdown-container {
        width: 100%;
        .space-3-flex {
          width: 100%;

          .countdown-item {
            min-width: 70px !important;
          }
        }
      }
    }
  }
}
