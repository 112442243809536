@import '@/styles/vars';

.reveal-popup {
  stroke-width: 1px;
  stroke: rgba(131, 134, 149, 0.2);
  backdrop-filter: blur(10px);
  padding-bottom: 0px;

  .title {
    font-stretch: expanded;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .space-3-modal-content {
      padding: 40px 20px 16px 20px !important;
    }
  }

  .mob-reveal-popup--pagination {
    .btn-prev,
    .btn-next {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      .space-3-icon {
        font-size: 10px;
      }
    }
  }
}

.reveal-popup-small {
  background: url('/images/clip-path-rect.svg') no-repeat center !important;
  clip-path: path(
    'M739.872 374H4C1.79086 374 0 372.209 0 370V38.6387C0 37.2492 0.721123 35.9592 1.90478 35.2314L58.2362 0.592651C58.8664 0.205139 59.5917 0 60.3315 0H531.569H796C798.209 0 800 1.79086 800 4V328.111C800 329.374 799.404 330.562 798.392 331.317L742.264 373.206C741.573 373.721 740.734 374 739.872 374Z'
  );
}

.reveal-popup-medium {
  height: 626px;
  background: url('/images/clip-path-rect-medium.svg') no-repeat center !important;
}

.reveal-popup-large {
  height: 671px;
  background: url('/images/clip-path-rect-large.svg') no-repeat center !important;

  .space-3-modal-content {
    height: 671px;
  }
}

.revealed-card {
  width: 240px;
  height: 240px;
  clip-path: polygon(7.2% 0, 100% 0, 100% 92%, 92.7% 100%, 0 100%, 0 6%);
}
