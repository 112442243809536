@import '@/styles/vars';

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-pagination {
    background-color: initial;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    width: 32px !important;
    height: 32px !important;
    transition: none;

    &:hover {
      border: none !important;
      background: #043c39 !important;
      color: unset !important;

      span {
        color: #f1f2f3 !important;
      }
    }
  }

  .space-3-pagination {
    margin: 0 8px;
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
    }

    &-item {
      padding: 0 !important;
      width: 32px !important;
      height: 32px !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      font-weight: 500;

      &-active {
        border-color: $PRIMARY;
        background-color: initial !important;

        a {
          color: $PRIMARY;
        }
      }

      &-link:hover,
      &:hover:not(.space-3-pagination-item-active) {
        background: #043c39;
        border: none;
      }

      a {
        display: flex !important;
        justify-content: center;
        align-items: center;

        padding: 0;
        height: 100%;
        line-height: 32px;
      }
    }

    &-prev,
    &-next {
      height: 32px !important;
      border-radius: 4px !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: none !important;

      &:hover {
        border: none !important;
        background: #043c39 !important;

        button {
          color: #f1f2f3 !important;
          border: none !important;
        }
      }
    }
  }
}
