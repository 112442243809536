@import '@/styles/vars';

.btn-arrow {
  width: 28px !important;
  height: 28px !important;
  clip-path: polygon(18% 0, 100% 0, 100% 82%, 82% 100%, 0 100%, 0 18%);
  background-color: $PRIMARY !important;
  opacity: 0 !important;

  &:hover {
    opacity: 1 !important;
    background-color: $PRIMARY !important;
  }

  &::after {
    display: none !important;
  }
}
