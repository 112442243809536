@import '@/styles/vars.scss';

.space-3-collapse {
  border-radius: 4px;
  border: 1px solid rgba(241, 242, 243, 0.12);
  margin-bottom: 16px;
}

.space-3-collapse-expand-icon {
  .burn-collapse-arrow {
    transition: $TRANSITION_MAIN !important;
    transform: rotate(0deg);

    &-active {
      transform: rotate(-180deg);
    }
  }
}

.space-3-collapse-header {
  .space-3-collapse-header-text .space-3-typography {
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 8px 16px !important;

    .space-3-typography {
      font-size: 14px !important;
      line-height: 1.1;
    }
  }
}

.space-3-collapse-content {
  background-color: transparent !important;
  border-top: 1px solid rgba(241, 242, 243, 0.12) !important;

  .space-3-collapse-content-box {
    padding-top: 12px !important;
    padding-bottom: 0 !important;
  }

  .space-3-typography {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .space-3-typography {
      font-size: 13px !important;
    }
  }
}
