@import '@/styles/vars';

.burn-rule-container {
  .rule-item {
    &--title,
    .space-3-icon {
      color: #a3a5ae;
    }

    &--desc {
      font-size: 16px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.2;

      .space-3-btn {
        background-color: transparent !important;
      }
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .rule-item {
      &--title {
        font-size: 13px !important;
      }

      &--desc {
        font-size: 14px !important;
      }
    }
  }
}

.rule-item--tooltip {
  max-width: 216px;
  .space-3-tooltip-inner {
    background-color: #1f262f;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    max-width: 160px !important;
  }
}
