@import '@/styles/vars';

.home-page-container {
  margin-top: 40px;

  .home-page--title {
    text-align: center;
    font-stretch: expanded;
    font-size: 46px;
    font-weight: 700;
    letter-spacing: 0.2px;

    background: linear-gradient(270deg, #fff 12.46%, #0fdbd1 89.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 20px 20px 0;
    margin-top: 0 !important;

    .home-page--title {
      font-size: 24px;
    }
  }
}
